
import BudgetsCommentsService from "@/modules/budgets-comments/budgets-comments-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            BudgetsCommentsService.create(payload).then(response => {
                resolve({
                    message: "BudgetsComments criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar BudgetsComments",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            BudgetsCommentsService.update(payload.id,payload).then(response => {
                resolve({
                    message: "BudgetsComments atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar BudgetsComments",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            BudgetsCommentsService.remove(id).then(response => {
                resolve({
                    message: "BudgetsComments removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover BudgetsComments",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            BudgetsCommentsService.delete(id).then(response => {
                resolve({
                    message: "BudgetsComments deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar BudgetsComments",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            BudgetsCommentsService.get(id).then(response => {
                resolve({
                    message: "BudgetsComments encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsComments",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            BudgetsCommentsService.paginate(query).then(response => {
                resolve({
                    message: "BudgetsComments encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsComments",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            BudgetsCommentsService.all().then(response => {
                resolve({
                    message: "BudgetsComments encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsComments",
                    success: false,
                    error
                });
            })
        })
    }
}
